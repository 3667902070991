import React from 'react'
import Eye_Journal from '../components/Eye'
import Header from '../components/Header'
import Footer from '../components/Footer'
export default function JournalPage() {
  return (
    <>
    
    <Eye_Journal/>
    <Header/>
    <Footer/>
    </>
  )
}

