import React from 'react'
import Header from '../components/Header'
import Store from '../components/Store'
import Footer from '../components/Footer'
export default function StorePage() {
  return (
    <>
       
       <Header/>
       <Store/>
      <Footer/>
    </>
  )
}
