import React from 'react'

import Header from '../components/Header'
import Footer from '../components/Footer'
import Eye from '../components/Eye_Resume'
export default function ResumePage() {
  return (
    <>
    <Eye/>
    <Header/>
    <Footer/>
    </>
  )
}
