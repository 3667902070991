import React, { useEffect, useState } from 'react';
import emailjs from 'emailjs-com';
import { useTranslation } from "react-i18next";
import ParticalAnimation from '../Pages/ParticalAnimation';

export default function Request() {
    const { t } = useTranslation();
    const [captchaToken, setCaptchaToken] = useState('');
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false); // State for disabling button
    const [cooldownActive, setCooldownActive] = useState(false); // State for cooldown

    useEffect(() => {
        const handleLoad = () => {
            setIsPageLoaded(true); 
            if (window.turnstile && !document.getElementById('turnstile-element-rendered')) {
                window.turnstile.render('#turnstile-element', {
                    sitekey: '0x4AAAAAAAz3nEpjK26KsZyL',
                    callback: (token) => setCaptchaToken(token),
                });
                document.getElementById('turnstile-element').setAttribute('id', 'turnstile-element-rendered');
            }
        };
        if (document.readyState === 'complete') handleLoad();
        else window.addEventListener('load', handleLoad);

        return () => window.removeEventListener('load', handleLoad);
    }, []);

    const startCooldown = () => {
        setCooldownActive(true);
        setTimeout(() => {
            setCooldownActive(false);
        }, 10000); // Cooldown period (e.g., 10 seconds)
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (isSubmitting || cooldownActive) return; // Prevent multiple submissions
        if (!captchaToken) {
            alert('Please complete the CAPTCHA');
            return;
        }

        setIsSubmitting(true); // Disable button immediately after click

        const form = event.target;

        emailjs.sendForm(
            'service_y0t8ex5',       // Replace with your EmailJS service ID
            'template_7yvygoo',      // Replace with your EmailJS notification template ID
            form,
            'vz1gDcqsyuHVY1xUk'      // Replace with your EmailJS user ID
        )
        .then(() => {
            // Send auto-response to user
            emailjs.sendForm(
                'service_y0t8ex5',
                'template_fia45cp', // Your auto-response template ID
                form,
                'vz1gDcqsyuHVY1xUk'
            )
            .then((result) => {
                alert('Email successfully sent!');
                console.log(result.text);
                startCooldown(); // Start cooldown after successful submission
            })
            .catch((error) => {
                alert('Failed to send auto-response email. Please try again later.');
                console.error(error.text);
            });
        })
        .catch((error) => {
            alert('Failed to send email. Please try again later.');
            console.error(error.text);
        })
        .finally(() => {
            setIsSubmitting(false); // Reset submitting state after email is sent
        });
    };

    return (
        <>
            <main className="over-hidden particles-container">
                {isPageLoaded && <ParticalAnimation />}
                <div id="contact" className="contact-area over-hidden aboutbgdark" style={{ backgroundColor: "transparent" }}>
                    <div id="hero-btn" className="contact-wrapper over-hidden position-relative" style={{ marginTop: "150px" }}>
                        <div className="container">
                            <div className="row align-items-start">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="title text-center">
                                        <span className="meta-text-color d-block mb-6">{t("CONTACT ME")}</span>
                                        <h2>{t("Let’s Start A New Project")}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row flex align-items-center justify-content-center">
                                <div className="col-xl-10 col-lg-8 col-md-12 col-sm-12 col-12">
                                    <div className="contact-wrapper">
                                        <div className="contact-form mt-45">
                                            <form onSubmit={handleSubmit} id="contact-form">
                                                <div className="contact-info pt-20">
                                                    <div className="row">
                                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pr6 mb-12">
                                                            <input
                                                                className="name w-100 theme-border pl-20 pt-15 pb-15 pr-10 form-color border-radius5 openS-font-family"
                                                                type="text" name="from_name" id="inputName"
                                                                placeholder="Your Name" required
                                                            />
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pl6 pr-12 mb-12">
                                                            <input
                                                                className="email w-100 theme-border pl-20 pt-15 pb-15 pr-10 form-color border-radius5 openS-font-family"
                                                                type="email" name="from_email" id="inputEmail"
                                                                placeholder="Your Email" required
                                                            />
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pr6 mb-12">
                                                            <input
                                                                className="phone w-100 theme-border pl-20 pt-15 pb-15 pr-10 form-color border-radius5 openS-font-family"
                                                                type="text" name="phone" id="inputPhone"
                                                                placeholder="Your Phone"
                                                            />
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pl6 pr-12 mb-12">
                                                            <input
                                                                className="subject w-100 theme-border pl-20 pt-15 pb-15 pr-10 form-color border-radius5 openS-font-family"
                                                                type="text" name="subject" id="inputSubject"
                                                                placeholder="Your Subject"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pl-12 pr-12 mb-12">
                                                            <textarea
                                                                className="massage w-100 theme-border pl-20 pt-15 pr-10 primary-color border-radius5 openS-font-family"
                                                                name="message" id="inputMessage"
                                                                placeholder="Start writing message here"
                                                                required
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                    <div id="turnstile-element" className="mt-20 mb-20"></div>
                                                    <button
                                                        className="slider-content btn position-relative over-hidden text-white d-inline-block theme-bg white-text text-uppercase"
                                                        type="submit" name="submit"
                                                        disabled={isSubmitting || cooldownActive} // Disable on cooldown
                                                    >
                                                        {isSubmitting ? 'Sending...' : 'Submit Now'}
                                                    </button>
                                                </div>
                                            </form>
                                            <p className="form-message mt-20"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
