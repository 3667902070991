import React, { useState, useEffect } from 'react';
import './Eye.css';
import Journal from './Journal';
import Header from './Header';
import { useTranslation } from 'react-i18next';
import { FaArrowRightLong } from "react-icons/fa6";
import ParticalAnimation from '../Pages/ParticalAnimation';
import { useNavigate } from 'react-router-dom';

const Eye = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  const [blink, setBlink] = useState(false);
  const [showJournal, setShowJournal] = useState(false);
  const [password, setPassword] = useState('');

  const correctPassword = 'Gustavo';

  const handleMouseMove = (e) => {
    setMouseX(e.clientX);
    setMouseY(e.clientY);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setBlink(true);
      setTimeout(() => setBlink(false), 200);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordSubmit = () => {
    if (password === correctPassword) {
      setShowJournal(true);
    } else {
      alert('Incorrect password. Please try again.');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handlePasswordSubmit();
    }
  };

  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  const handleInstagramClick = () => {
    if (isMobileDevice()) {
      window.location.href = 'instagram://user?username=svmius';
    } else {
      window.open('https://www.instagram.com/svmius/', '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <>
      <Header />
      <div>
        {showJournal ? (
          <Journal />
        ) : (
          <div className="password-pg pt-600 particles-container bg-transparent" onMouseMove={handleMouseMove}>
            <ParticalAnimation />
            <div className="eye-container">
              <div className={`eye ${blink ? 'blink' : ''}`}>
                <div className="pupil-container">
                  <div className='pupil-outer'>
                    <div
                      className="pupil"
                      style={{
                        transform: `translate(${(mouseX - window.innerWidth / 2) / 65}px, ${(mouseY - window.innerHeight / 2) / 60}px)`,
                      }}
                    >
                      <span className='pupil-dot'></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='pass-proteted-area pt-100'>
              <h2 className='pass-heading'> {t("This content is protected.")}</h2>
              <p> {t("To view, please enter the password.")}</p>
              <br />
              <div className="bio-inputs pass-inputs flex justify-center">
                <input
                  type="password"
                  placeholder="Enter your password"
                  className='w-70'
                  value={password}
                  onChange={handlePasswordChange}
                  onKeyDown={handleKeyDown}
                />
                <button type="button" className="ml-2 p-2" onClick={handlePasswordSubmit}> <FaArrowRightLong size={25} /></button>
              </div>
              <p className="text-xs text-center text-muted mt-2">
                * To gain access to this section, kindly send a request by selecting the options below.
              </p>

              <div className="flex text-center align-items-center justify-content-center pass-pg-btn pt-40 gap-20 mt-10">
                <div>
                  <button
                    onClick={() => navigate('/request')}
                    className="btn position-relative over-hidden theme-bg text-uppercase transition5 mr-3"
                  >
                    {t("Send Request")}
                  </button>
                </div>
                <div>
                  <button
                    onClick={handleInstagramClick}
                    className="btn position-relative about-me-btn over-hidden bg-transparent main-border text-uppercase transition5"
                  >
                    {t("DM on Insta")}
                    <span className="mr-2 rotate-hover">
                      <i className="fab fa-instagram ml-2 instagram text-center pr-0 text-white d-inline rotate transition-3"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Eye;
