import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Clarity from '@microsoft/clarity'; // Import Clarity

// Initialize Microsoft Clarity
const projectId = "oz0f9phfiq"

Clarity.init(projectId);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
