import React from 'react'
import Header from '../components/Header'
import Request from '../components/Request'
import Footer from '../components/Footer'

export default function RequestPage() {
  return (
    <>
       
       <Header/>
       <Request/>
      <Footer/>
    </>
  )
}
